import {
  MenuListParams,
  LoginParams,
  getMenuListResultModel,
  getLoginResultModel,
  getUploadTempKeyModel,
  getCategoryTreeResultModel,
  CategoryTreeParams,
  UserPrivCheckParams,
  GetCollectionParams,
  getAllCollectionModel,
} from './model/commonModel'
import {
  adminRequest,
  pmsRequest,
  wmsRequest,
  commonRequest,
  twoiWmsRequest,
  omsRequest,
} from '@admin/utils/http/axios'
enum Api {
  MenuList = '/admin/user/menu/get',
  menuList = '/admin/user/permissions',
  Login = '/admin/feishu/login',
  getBestHealth = '/admin/health',
  doLogout = '/admin/user/logout',
  getProductSize = '/admin/tracking/getEffectiveConfigList',
  getUploadTempKey = '/mcapi/goods/pictures/upload/tempKey', // only prod request url
  getCategoryTree = '/admin/category/getCategoryTree',
  getFieldsFilterList = '/mcapi/me/goods/list/field/filter',
  getFieldsDisplayList = '/mcapi/me/goods/list/field/display',
  getFieldsFilterListByPurchase = '/stocking_decision/order/filter_list',
  getFieldsDisplayListByPurchase = '/stocking_decision/order/field_list',
  getDisplayList = '/mcapi/me/goods/list/data',
  getDisplayListByPurchase = '/stocking_decision/order/list',
  getUserInfo = '/api/user/isLogin',
  UserPrivCheck = '/admin/user/userPrivCheck',
  getAllCollection = '/admin/marketing/collection_new/all_list?site=us&title=&sort_field=&order_by=&per_page=10000&page=1',
  createDownloadTask = '/task/download/create_download_task',
  getTaskList = '/task/batch/task_list', // 获取任务列表
  getExecuteTask = '/task/batch/execute_task', // 批量任务
}

export const queryMenuList = (params: MenuListParams) =>
  adminRequest.get<getMenuListResultModel>({ url: Api.menuList, params })

export const login = (params: LoginParams) =>
  adminRequest.post<getLoginResultModel>({ url: Api.Login, params })
export const doLogout = () => adminRequest.post<getLoginResultModel>({ url: Api.doLogout })
export const getUserInfo = () => adminRequest.post<getLoginResultModel>({ url: Api.getUserInfo })

export const userPrivCheck = (params: UserPrivCheckParams) =>
  adminRequest.post<getLoginResultModel>({ url: Api.UserPrivCheck, params })

export const getProductSize = (params: any) => adminRequest.get({ url: Api.getProductSize, params })
/**
 * 获取所有类目
 * @param params GetCollectionParams
 * @returns
 */
export const getAllCollection = (params: GetCollectionParams): Promise<getAllCollectionModel> =>
  adminRequest.get({ url: Api.getAllCollection, params })
/**
 * 获取上传密匙
 * @returns getUploadTempKeyModel
 */
export const getUploadTempKey = (): Promise<getUploadTempKeyModel> =>
  adminRequest.get({ url: Api.getUploadTempKey })
export const getCategoryTreeList = (params: CategoryTreeParams) =>
  adminRequest.get<getCategoryTreeResultModel>({ url: Api.getCategoryTree, params })

/**
 * 获取公共的table搜索form的表单信息
 * @returns getUploadTempKeyModel
 */
export const getFieldsFilterListApi = (params: { view: string }) =>
  adminRequest.get({ url: Api.getFieldsFilterList, params })

/**
 * 获取公共的table列表表头等信息
 * @returns getUploadTempKeyModel
 */
export const getFieldsDisplayListApi = (params: { view: string }) =>
  adminRequest.get({ url: Api.getFieldsDisplayList, params })

/**
 * 获取公共的table搜索form的表单信息
 * @returns getUploadTempKeyModel
 */
export const getFieldsFilterListByPurchaseApi = (params: { view: string }) =>
  pmsRequest.get({ url: Api.getFieldsFilterListByPurchase, params })

/**
 * 获取公共的table列表表头等信息
 * @returns getUploadTempKeyModel
 */
export const getFieldsDisplayListByPurchaseApi = (params: { view: string }) =>
  pmsRequest.get({ url: Api.getFieldsDisplayListByPurchase, params })

/**
 * 获取公共的table列表表头等信息
 * @returns getUploadTempKeyModel
 */
export const getDisplayListApi = (params: { view: string }) =>
  adminRequest.post({ url: Api.getDisplayList, params })

/**
 * 获取公共的table列表表头等信息
 * @returns getUploadTempKeyModel
 */
export const getDisplayListByPurchaseApi = (params: { view: string }) =>
  pmsRequest.post({ url: Api.getDisplayListByPurchase, params })

export const getExecuteTask = (params: any) => pmsRequest.post({ url: Api.getExecuteTask, params })

/**
 * 床
 * @returns getUploadTempKeyModel
 */
export const getExportTaskList = (data: any): Promise<any> => {
  data.apiSystem = undefined

  return adminRequest.post({ url: Api.getTaskList, data })
}
export const createDownloadTask = (data: { type: string; param: any }) => {
  const requestApiMap = {
    wms: wmsRequest,
    pms: pmsRequest,
    common: commonRequest,
    twoiWms: twoiWmsRequest,
    oms: omsRequest,
  }
  const requestApiType = requestApiMap[data.param.apiSystem] || pmsRequest
  data.param.apiSystem = undefined
  return requestApiType.post({ url: Api.createDownloadTask, data })
}

//心跳检测探针接口-用于查找最佳线路
export const getBestHealth = (healthUrl: any) =>
  adminRequest.get<any>({ url: Api.getBestHealth, healthUrl })
