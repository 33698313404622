import { LAYOUT } from '@admin/router/constant'

// tms
const TmsRouter = {
  path: '/tms',
  name: '',
  component: LAYOUT,
  children: [
    // 自营包裹
    {
      path: '/tms/package/list',
      name: 'TMSPackageList',
      component: () => import('@admin/views/tms/package/list/index.vue'),
      meta: {
        title: '自营包裹',
      },
    },
    {
      path: '/tms/package/box',
      name: 'TMSPackageBox',
      component: () => import('@admin/views/tms/package/box/index.vue'),
      meta: {
        title: '集箱管理',
      },
    },
    {
      path: '/tms/package/flight',
      name: 'TMSPackageFlight',
      component: () => import('@admin/views/tms/package/flight/index.vue'),
      meta: {
        title: '提单管理',
      },
    },
    // 渠道管理
    {
      path: '/tms/channel/logistics-list',
      name: 'TMSChannelLogisticsList',
      component: () => import('@admin/views/tms/channel/logistics/index.vue'),
      meta: {
        title: '物流渠道列表',
      },
    },
    {
      path: '/tms/channel/freight-list',
      name: 'TMSChannelFreightList',
      component: () => import('@admin/views/tms/channel/freight/index.vue'),
      meta: {
        title: '运费版本管理',
      },
    },
    {
      path: '/tms/channel/distribute-rule-engine',
      name: 'TMSChannelDistributeRuleEngine',
      component: () => import('@admin/views/tms/channel/ruleEngine/index.vue'),
      meta: {
        title: '渠道分配规则引擎',
      },
    },
    {
      path: '/tms/package-sorting/record',
      name: 'TMSPackageSortingRecord',
      component: () => import('@admin/views/tms/packageSorting/record/index.vue'),
      meta: {
        title: '包裹分拣记录',
      },
    },
    // {
    //   path: '/oms/abnormally/list',
    //   name: 'OMSAbnormallyList',
    //   component: () => import('@admin/views/oms/abnormally/list/index.vue'),
    //   meta: {
    //     title: '异常单列表',
    //   },
    // },
    // {
    //   path: '/oms/order-manage/order-list',
    //   name: 'OmsOrderManageOrderList',
    //   component: () => import('@admin/views/oms/orderManage/order/index.vue'),
    //   meta: {
    //     title: '订单列表',
    //   },
    // },
    // {
    //   path: '/oms/order-manage/order-detail',
    //   name: 'OMSOrderManageOrderDetail',
    //   component: () => import('@admin/views/oms/orderManage/order/detail.vue'),
    //   meta: {
    //     title: '订单详情',
    //   },
    // },
  ],
}

export default TmsRouter
