import { LAYOUT } from '@admin/router/constant'

// 客退管理
const customerReturnRouter = {
  path: '/customer-return-management',
  name: '',
  component: LAYOUT,
  children: [
    {
      path: '/customer-return-management/customer-return-list',
      name: 'customerReturnList',
      component: () =>
        import('@admin/views/wms/customerReturnManagement/customerReturnList/index.vue'),
      meta: { title: '客退列表' },
    },
    {
      path: '/customer-return-management/customer-return-detail',
      name: 'customerReturnDetail',
      component: () =>
        import('@admin/views/wms/customerReturnManagement/customerReturnList/detail.vue'),
      meta: { title: '客退单详情' },
    },
    {
      path: '/customer-return-sign/reception-desk',
      name: 'receptionDesk',
      component: () =>
        import(
          '@admin/views/wms/customerReturnManagement/customerReturnSign/receptionDesk/index.vue'
        ),
      meta: { title: '客退签收台' },
    },
    {
      path: '/customer-return-sign/sign-list',
      name: 'signList',
      component: () =>
        import('@admin/views/wms/customerReturnManagement/customerReturnSign/signList/index.vue'),
      meta: { title: '客退签收单' },
    },
    {
      path: '/customer-return-inspection/customer-inspection-basket',
      name: 'customerInspectionBasket',
      component: () =>
        import(
          '@admin/views/wms/customerReturnManagement/customerReturnInspection/customerInspectionBasket/index.vue'
        ),
      meta: { title: '质检篮子管理' },
    },
    {
      path: '/customer-return-inspection/customer-inspection-desk',
      name: 'customerInspectionDesk',
      component: () =>
        import(
          '@admin/views/wms/customerReturnManagement/customerReturnInspection/newDesk/index.vue'
        ),
      meta: { title: '客退质检台' },
    },
    {
      path: '/customer-return-inspection/customer-inspection-form',
      name: 'customerInspectionForm',
      component: () =>
        import(
          '@admin/views/wms/customerReturnManagement/customerReturnInspection/InspectionForm/index.vue'
        ),
      meta: { title: '客退质检单' },
    },
    {
      path: '/customer-return-storage/quality-goods-storage',
      name: 'qualityGoodsStorage',
      component: () =>
        import(
          '@admin/views/wms/customerReturnManagement/customerReturnStorage/qualityGoodsStorage/index.vue'
        ),
      meta: { title: '良品入库单' },
    },
    {
      path: '/customer-return-storage/defective-goods-storage',
      name: 'defectiveGoodsStorage',
      component: () =>
        import(
          '@admin/views/wms/customerReturnManagement/customerReturnStorage/defectiveGoodsStorage/index.vue'
        ),
      meta: { title: '次品入库单' },
    },
  ],
}

export default [customerReturnRouter]
