import { ADMIN_TOKEN_KEY } from 'framework/enums/cacheEnum'

export function getToken() {
  return localStorage.getItem(ADMIN_TOKEN_KEY)
}

export function setToken(token) {
  return localStorage.setItem(ADMIN_TOKEN_KEY, token)
}

export function removeToken() {
  return localStorage.setItem(ADMIN_TOKEN_KEY, '')
}

export function getLocalToken() {
  if (
    sessionStorage.getItem('rememberLoginsStatus') &&
    sessionStorage.getItem('rememberLoginsStatus') == 'false'
  ) {
    return sessionStorage.getItem(ADMIN_TOKEN_KEY)
  }
  return localStorage.getItem(ADMIN_TOKEN_KEY)
}
