import { LAYOUT } from '@admin/router/constant'

// gmp 人群
const GmpFullSiteRouter = {
  path: '/gmp',
  name: '',
  component: LAYOUT,
  children: [
    {
      path: '/gmp/fullSite/productActivityConfig',
      name: 'GmpFullSiteActivityConfigList',
      component: () => import('@admin/views/gmp/fullSiteOperation/productActivityConfig/index.vue'),
      meta: {
        title: '商品活动配置',
      },
    },
    {
      path: '/gmp/fullSite/productActivityConfigDetail',
      name: 'GmpFullSiteActivityConfigDetail',
      component: () => import('@admin/views/gmp/fullSiteOperation/productActivityConfig/detail.vue'),
      meta: {
        title: '商品活动配置详情',
      },
    },
  ],
}

export default GmpFullSiteRouter
