import { LAYOUT } from '@admin/router/constant'

// 管理系统路由
const crowdRouter = {
  path: '/crowd',
  name: '',
  component: LAYOUT,
  children: [
    {
      path: 'pack-list',
      name: 'CrowdPackList',
      component: () => import('@admin/views/crm/user-management/crowd/manage/index.vue'),
      meta: { helpUrl: '', title: '人群圈选' },
    },
    {
      path: 'cdp-field-list',
      name: 'cdpFieldList',
      component: () => import('@admin/views/system/cdpFieldList/index.vue'),
      meta: { helpUrl: '', title: '圈选字段配置' },
    },
    {
      path: 'pack-add',
      name: '',
      component: () => import('@admin/views/crm/user-management/crowd/manage/components/Add.vue'),
      meta: { helpUrl: '', title: '人群创建' },
    },
    {
      path: 'pack-detail',
      name: '',
      component: () => import('@admin/views/crm/user-management/crowd/manage/components/Add.vue'),
      meta: { helpUrl: '', title: '人群包详情' },
    },
  ],
}

export default [crowdRouter]
