import { adminRequest } from '@admin/utils/http/axios'

enum Api {
  sendVerification = '/admin/user/verification_code/send', // 发送验证码
  loginByVerification = '/admin/user/verification_code_login', // 验证码登陆
  loginByPW = '/admin/user/pw_login', // 密码登陆
  resetPW = '/admin/user/reset_pw', // 重置密码
}

export const sendVerification = (params: {
  option?: string
  send_to?: string
  target_no?: string
}) => adminRequest.get({ url: Api.sendVerification, params })

export const loginByVerification = (data: { username: string; verification_code: string }) =>
  adminRequest.post({ url: Api.loginByVerification, data })

export const loginByPW = (data: { username: string; password: string }) =>
  adminRequest.post({ url: Api.loginByPW, data })

export const resetPW = (data: {
  old_pw: string | null
  new_pw: string
  verification_code: string | null
  user_id?: number
}) => adminRequest.post({ url: Api.resetPW, data })
