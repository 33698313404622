import { createApp, reactive, Component, nextTick } from 'vue'

export function useModalState(expose) {
  const state = reactive({
    visible: true,
  })

  const toggle = (visible: boolean) => {
    state.visible = visible
  }

  const open = (props: Record<string, any>) => {
    Object.assign(state, props)

    nextTick(() => {
      toggle(true)
    })
  }

  const close = () => {
    toggle(false)
  }

  expose({ open, close, toggle })

  return {
    open,
    close,
    state,
    toggle,
  }
}

export function mountComponent(RootComponent: Component) {
  const app = createApp(RootComponent)
  const root = document.createElement('div')

  document.body.appendChild(root)

  return {
    instance: app.mount(root),
    unmount() {
      app.unmount()
      document.body.removeChild(root)
    },
  }
}
