import type { UserInfo } from 'framework/types/store'
// import type { ErrorMessageMode } from 'framework/types/axios'
import { defineStore } from 'pinia'
import { store } from '@admin/store'

import { loginByPW, loginByVerification, resetPW } from '@admin/api/system/login/login'
import { doLogout, login as loginByFeishu } from '@admin/api/common/common'
import { useMessage } from 'framework/hooks/web/useMessage'
import { setToken, removeToken } from 'framework/utils/auth'

import { useAppStore } from './app'
import { useMultipleTabStore } from './multipleTab'
import { router } from '@admin/router'

interface UserState {
  userId?: string | undefined
  userName?: string | undefined
  userInfo: Nullable<UserInfo>
  token?: string | undefined
  suffixUrl?: string | undefined
}

export const useUserStore = defineStore({
  id: 'app-user',
  persist: {
    enabled: true, //开启数据持久化
    strategies: [
      {
        key: 'app-user', //给一个要保存的名称
        storage: localStorage, //sessionStorage / localStorage 存储方式
      },
    ],
  },
  state: (): UserState => ({
    token: '',
    suffixUrl: '',
    userInfo: null,
  }),
  getters: {
    getUserId: (state) => state.userInfo?.id,
    getUserName: (state) => state.userInfo?.name,
  },
  actions: {
    logout() {
      return new Promise<void>((resolve, reject) => {
        const { createConfirm } = useMessage()

        createConfirm({
          iconType: 'warning',
          title: '温馨提醒',
          content: '确定要退出登录吗？',
          onOk: async () => {
            await doLogout()
            this.resetState()

            // userStore.logout有多处调用，避免写多次跳转登录页直接在store中写跳转
            router.push(`/login?redirect=${router.currentRoute.value.fullPath}`)

            resolve()
          },
          onCancel: () => reject(),
        })
      })
    },

    async setSuffixUrl(suffixUrl) {
      this.suffixUrl = suffixUrl
    },
    async loginByPassword(params) {
      const { data } = await loginByPW(params)
      this.setState(data)
    },
    async loginByAuthCode(params) {
      const { data } = await loginByFeishu(params)
      this.setState(data)
    },
    async loginByVerificationCode(params) {
      const { data } = await loginByVerification(params)
      this.setState(data)
    },
    async loginByResetPW(params) {
      try {
        const { data } = await resetPW(params)
        this.setState(data)
      } catch (error) {
        console.log('重置密码error:', error)
      }
    },
    setState(userInfo: any = {}) {
      const { token, id: uin } = userInfo
      this.token = token
      this.userInfo = userInfo
      try {
        window.aegis?.setConfig({
          uin, //上报userId
        })
      } catch (error) {
        console.log(error)
      }

      setToken(token)
    },
    setUserInfo(info: UserInfo | null) {
      this.userInfo = { ...this.userInfo, ...info } as any
    },
    resetState() {
      const tabStore = useMultipleTabStore()
      const appStore = useAppStore()
      appStore.resetAllState()
      tabStore.resetState()
      this.$reset()
      removeToken()
    },
  },
})

// Need to be used outside the setup
export function useUserStoreWithOut() {
  return useUserStore(store)
}
