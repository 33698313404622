import { LAYOUT } from '@admin/router/constant'

// 商品管理系统路由
const productRouter = {
  path: '/cosSystem',
  name: '',
  component: LAYOUT,
  children: [
    {
      path: '/operate/goods-publish-list',
      name: 'GoodsPublish',
      component: () => import('@admin/views/cos/goodsManagement/goodsPublish/index.vue'),
      meta: { helpUrl: '', title: '商品刊登' },
    },
    {
      path: '/operate/goods-shot-new',
      name: 'uploadPictures',
      component: () => import('@admin/views/cos/goodsManagement/uploadPictures/index.vue'),
      meta: { helpUrl: '', title: '上传图片' },
    },
    {
      path: '/operate/goods-size',
      name: 'goodsSizeList',
      component: () => import('@admin/views/cos/goodsManagement/goodsSize/index.vue'),
      meta: { helpUrl: '', title: '确认尺寸' },
    },
    {
      path: '/operate/goods-properties/new-develop',
      name: 'developInfo',
      component: () => import('@admin/views/cos/goodsManagement/info/index/develop.vue'),
      meta: { helpUrl: '', title: '开发信息' },
    },
    {
      path: '/operate/goods-properties/new-operate',
      name: 'operateInfo',
      component: () => import('@admin/views/cos/goodsManagement/info/index/operate.vue'),
      meta: { helpUrl: '', title: '运营信息' },
    },
    {
      path: '/operate/goods-properties/new-produce',
      name: 'productionInfo',
      component: () => import('@admin/views/cos/goodsManagement/info/index/production.vue'),
      meta: { helpUrl: '', title: '生产信息' },
    },
    {
      path: '/operate/goods-publish-view',
      name: '',
      component: () => import('@admin/views/cos/goodsManagement/goodsPublishInfo/index.vue'),
      meta: { helpUrl: '', title: '商品刊登编辑' },
    },
    {
      path: '/operate/batch-update-info',
      name: 'batchUpdate',
      component: () => import('@admin/views/cos/batchUpdate/index.vue'),
      meta: { helpUrl: '', title: '批量修改' },
    },
    {
      path: '/operate/goods-shot-edit-new',
      name: 'goods-shot-edit',
      component: () => import('@admin/views/cos/goodsManagement/uploadPictures/edit.vue'),
      meta: { helpUrl: '', title: '上传图片编辑' },
    },
    {
      path: '/operate/goods-size-edit',
      name: null,
      component: () => import('@admin/views/cos/goodsManagement/goodsSize/edit.vue'),
      meta: { helpUrl: '', title: '确认尺寸编辑' },
    },
    {
      path: '/operate/goods-properties-edit/new-develop',
      name: 'null',
      component: () => import('@admin/views/cos/goodsManagement/info/edit/develop.vue'),
      meta: { helpUrl: '', title: '开发属性编辑' },
    },
    {
      path: '/operate/goods-properties-edit/new-operate',
      name: null,
      component: () => import('@admin/views/cos/goodsManagement/info/edit/operate.vue'),
      meta: { helpUrl: '', title: '运营属性编辑' },
    },
    {
      path: '/operate/goods-properties-edit/new-produce',
      name: null,
      component: () => import('@admin/views/cos/goodsManagement/info/edit/production.vue'),
      meta: { helpUrl: '', title: '生产属性编辑' },
    },
    // {
    //   path: '/operate/stock-inquiry',
    //   name: 'stockInquiry',
    //   component: () => import('@admin/views/crm/operate/stockInquiry/index.vue'),
    //   meta: { helpUrl: '', title: '库存查询' },
    // },
    {
      path: '/operate/undelivered-quantity',
      name: 'undeliveredQuantity',
      component: () => import('@admin/views/crm/operate/stockInquiry/undelivered-quantity.vue'),
      meta: { helpUrl: '', title: '未发数量查询' },
    },
    {
      path: '/operate/in-transit-stock',
      name: 'inTransitStock',
      component: () => import('@admin/views/crm/operate/stockInquiry/in-transit-stock.vue'),
      meta: { helpUrl: '', title: '在途库存查询' },
    },
    {
      path: '/operate/stock-change',
      name: 'stockChange',
      component: () => import('@admin/views/crm/operate/stockInquiry/stock-change.vue'),
      meta: { helpUrl: '', title: '库存变化记录' },
    },
    {
      path: '/operate/pre-order-date',
      name: 'preOrderDate',
      component: () => import('@admin/views/crm/operate/stockInquiry/pre-order-date.vue'),
      meta: { helpUrl: '', title: 'Pre Order日期记录' },
    },
    {
      path: '/operate/purchase-order',
      name: 'stockInquiryPurchaseOrder',
      component: () => import('@admin/views/crm/operate/stockInquiry/purchase-order.vue'),
      meta: { helpUrl: '', title: '马帮采购单查询' },
    },
    {
      path: '/operate/inventory-synchronization',
      name: 'inventorySynchronization',
      component: () =>
        import('@admin/views/cos/goodsManagement/inventorySynchronization/components/SyncRule.vue'),
      meta: { helpUrl: '', title: '同步规则' },
    },
    {
      path: '/operate/synchronization-rule-add',
      name: 'inventorySynchronizationAdd',
      component: () =>
        import('@admin/views/cos/goodsManagement/inventorySynchronizationDetail/add.vue'),
      meta: { helpUrl: '', title: '同步规则新增' },
    },
    {
      path: '/operate/synchronization-rule-edit',
      name: 'inventorySynchronizationEdit',
      component: () =>
        import('@admin/views/cos/goodsManagement/inventorySynchronizationDetail/add.vue'),
      meta: { helpUrl: '', title: '同步规则编辑' },
    },
    {
      path: '/operate/synchronization-rule-detail',
      name: 'inventorySynchronizationDetail',
      component: () =>
        import('@admin/views/cos/goodsManagement/inventorySynchronizationDetail/detail.vue'),
      meta: { helpUrl: '', title: '同步规则详情' },
    },
    {
      path: '/operate/goodab',
      name: 'goodAb',
      component: () => import('@admin/views/cos/goodsManagement/goodab/index.vue'),
      meta: { helpUrl: '', title: '商品图AB' },
    },
    {
      path: '/operate/goodab-edit',
      name: '',
      component: () => import('@admin/views/cos/goodsManagement/goodab/edit.vue'),
      meta: { helpUrl: '', title: '商品图AB编辑' },
    },
    {
      path: '/operate/goods-match/list',
      name: '',
      component: () => import('@admin/views/crm/operate/goodsMatch/index.vue'),
      meta: { helpUrl: '', title: '商品搭配' },
    },
    {
      path: '/operate/goods-match/edit',
      name: '',
      component: () => import('@admin/views/crm/operate/goodsMatch/edit.vue'),
      meta: { helpUrl: '', title: '商品搭配编辑' },
    },
    {
      path: '/operate/product-pool',
      name: 'productPool',
      component: () => import('@admin/views/cos/goodsManagement/productPool/index.vue'),
      meta: { helpUrl: '', title: '商品池' },
    },
    {
      path: '/operate/product-pool-edit',
      name: 'productPoolEdit',
      component: () => import('@admin/views/cos/goodsManagement/productPool/edit.vue'),
      meta: { helpUrl: '', title: '编辑/查看商品池' },
    },
    {
      path: '/nominate/feed-fb',
      name: 'feedFb',
      component: () => import('@admin/views/crm/nominate/feed/feed-fb.vue'),
      meta: { helpUrl: '', title: 'FB商品' },
    },
    {
      path: '/nominate/edit',
      name: '',
      component: () => import('@admin/views/crm/nominate/feed/edit.vue'),
      meta: { helpUrl: '', title: 'Feed商品编辑' },
    },
    {
      path: '/nominate/feed-tt',
      name: 'feedTt',
      component: () => import('@admin/views/crm/nominate/feed/feed-tt.vue'),
      meta: { helpUrl: '', title: 'TT商品' },
    },
    {
      path: '/nominate/feed-gg',
      name: 'feedGg',
      component: () => import('@admin/views/crm/nominate/feed/feed-gg.vue'),
      meta: { helpUrl: '', title: 'GG商品' },
    },
    {
      path: '/configurations/launchChannel',
      name: 'launchChannel',
      component: () => import('@admin/views/crm/nominate/configurations/feed/launch-channel.vue'),
      meta: { helpUrl: '', title: '投放渠道配置' },
    },
    {
      path: '/configurations/feed',
      name: 'feed',
      component: () => import('@admin/views/crm/nominate/configurations/feed/feed.vue'),
      meta: { helpUrl: '', title: 'Feed配置' },
    },
    {
      path: '/configurations/feedProduct',
      name: 'feedProduct',
      component: () => import('@admin/views/crm/nominate/configurations/feed/feed-product.vue'),
      meta: { helpUrl: '', title: 'Feed商品名配置' },
    },
    {
      path: '/configurations/productSeo',
      name: 'productSeo',
      component: () => import('@admin/views/crm/nominate/configurations/feed/product-seo.vue'),
      meta: { helpUrl: '', title: '商品SEO配置' },
    },
    {
      path: '/configurations/hotWord',
      name: 'hotWord',
      component: () => import('@admin/views/crm/nominate/configurations/feed/hot-word.vue'),
      meta: { helpUrl: '', title: '投放热词配置' },
    },
    {
      path: '/permission/configuration',
      name: 'configuration',
      component: () => import('@admin/views/system/attributeConfig/index.vue'),
      meta: { helpUrl: '', title: '属性配置' },
    },
    {
      path: '/permission/category-config',
      name: '',
      component: () => import('@admin/views/system/categoryConfig/index.vue'),
      meta: { helpUrl: '', title: '品类配置' },
    },
    {
      path: '/newProductDevelopment/newProductDevelopment-monitoring',
      name: 'newProductDevelopmentMonitoring',
      component: () =>
        import('@admin/views/cos/goodsManagement/productDevelopmentMonitoring/index.vue'),
      meta: { helpUrl: '', title: '新品开发进度监控' },
    },
    {
      path: '/operate/stock-inquiry',
      name: 'stockQuery',
      component: () => import('@admin/views/cos/inventoryManagement/stockQuery/index.vue'),
      meta: { helpUrl: '', title: '库存查询' },
    },
    {
      path: '/cos/warehouse-inventory-query',
      name: 'warehouseInventoryQuery',
      component: () =>
        import('@admin/views/cos/inventoryManagement/warehouseInventoryQuery/index.vue'),
      meta: { helpUrl: '', title: '分仓库存查询' },
    },
    {
      path: '/cos/inventory-change-log',
      name: 'inventoryChangeLog',
      component: () => import('@admin/views/cos/inventoryManagement/inventoryChangeLog/index.vue'),
      meta: { helpUrl: '', title: '库存变动日志' },
    },
    {
      path: '/cos/in-transit-inventory',
      name: 'inTransitInventory',
      component: () =>
        import(
          '@admin/views/cos/inventoryManagement/warehouseInventoryQuery/inTransitInventory.vue'
        ),
      meta: { helpUrl: '', title: '商品在途库存' },
    },
    {
      path: '/cos/unshipped-inventory',
      name: 'unshippedInventory',
      component: () =>
        import(
          '@admin/views/cos/inventoryManagement/warehouseInventoryQuery/unshippedInventory.vue'
        ),
      meta: { helpUrl: '', title: '商品未发库存' },
    },
    {
      path: '/cos/image-search',
      name: 'ImageSearch',
      component: () => import('@admin/views/cos/imSearch/index.vue'),
      meta: { title: '以图搜图', icon: 'icon-image-search' },
    },
    {
      path: '/cos/clearance-goods',
      name: 'CosClearanceGoodsList',
      component: () => import('@admin/views/cos/productOperation/clearanceGoods/index.vue'),
      meta: { helpUrl: '', title: '清仓商品' },
    },
    {
      path: '/cos/store-available-stock',
      name: 'storeAvailableStock',
      component: () => import('@admin/views/cos/inventoryManagement/storeAvailableStock/index.vue'),
      meta: { helpUrl: '', title: '店铺在售库存' },
    },
  ],
}

export default productRouter
