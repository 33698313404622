import { LAYOUT } from '@admin/router/constant'

const influencerRouter = {
  path: '/influencer',
  name: 'influencer',
  component: LAYOUT,
  children: [
    {
      path: '/influencer/member',
      name: 'influencerMember',
      component: () => import('@admin/views/crm/influencerManager/member/index.vue'),
      meta: { helpUrl: '', title: '红人管理' },
    },
    {
      path: '/influencer/member/edit',
      name: 'influencerMemberEdit',
      component: () => import('@admin/views/crm/influencerManager/member/edit.vue'),
      meta: { helpUrl: '', title: '编辑红人信息' },
    },
    {
      path: '/influencer/order',
      name: 'influencerOrder',
      component: () => import('@admin/views/crm/influencerManager/order/index.vue'),
      meta: { helpUrl: '', title: '红人订单' },
    },
  ],
}

export default influencerRouter
