import { LAYOUT } from '@admin/router/constant'

// 财务系统路由
const financeRouter = {
  path: '/financeSystem',
  name: '',
  component: LAYOUT,
  children: [
    {
      path: '/finance/financial-counting',
      name: 'financialCounting',
      component: () => import('@admin/views/scm/financeManager/financialCounting/index.vue'),
      meta: { helpUrl: '', title: '财务对账' },
    },
    {
      path: '/finance/financial-counting-edit',
      name: 'financialCountingEdit',
      component: () => import('@admin/views/scm/financeManager/financialCounting/edit.vue'),
      meta: { helpUrl: '', title: '财务对账处理详情' },
    },
  ],
}

export default financeRouter
