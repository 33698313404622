import { LAYOUT } from '@admin/router/constant'

// 物流系统路由
const flogisticsRouter = {
  path: '/tmsSystem',
  name: '',
  component: LAYOUT,
  children: [
    {
      path: '/package',
      name: 'packages',
      component: () => import('@admin/views/crm/logistics/packages/index.vue'),
      meta: { helpUrl: '', title: '自营包裹' },
    },
  ],
}

export default flogisticsRouter
