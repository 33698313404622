import type { AppRouteModule } from '@admin/router/types'
import basicRoutes from './basic'

// import.meta.globEager() 直接引入所有的模块 Vite 独有的功能
const modules: any = import.meta.globEager('./modules/**/*.ts')
const routeModuleList: AppRouteModule[] = []

// 加入到路由集合中
Object.keys(modules).forEach((key) => {
  const mod = modules[key].default || {}
  const modList = Array.isArray(mod) ? [...mod] : [mod]
  routeModuleList.push(...modList)
})

export const constantRoutes = [...routeModuleList, ...basicRoutes]
