export const ENV = {
  INTRANET: 'intranet',
  INNER: 'inner',
  TEST: 'test',
  PRE: 'pre',
  PROD: 'prod',
  LOCAL: 'local',
}

export const REQUEST_TYPE = {
  ADMIN: 'admin',
  PMS: 'pms',
  IMS: 'ims',
  WMS: 'wms',
  TWOI_WMS: 'twoiWms',
  OMS: 'oms',
  TMS: 'tms',
  LANDONG: 'landong',
  COMMON: 'common',
  APP_KEY: 'app_key',
  CDB: 'cdb',
  COPS: 'cops',
  KOL: 'kol',
}

const config: any = {
  [ENV.INNER]: {
    [REQUEST_TYPE.ADMIN]: 'http://192.168.20.9',
    [REQUEST_TYPE.PMS]: 'http://192.168.20.9:8082',
    [REQUEST_TYPE.IMS]: 'http://192.168.20.9:8084',
    [REQUEST_TYPE.WMS]: 'http://192.168.20.9:8085',
    [REQUEST_TYPE.OMS]: 'http://192.168.20.9:8087',
    [REQUEST_TYPE.TMS]: 'http://192.168.20.9:8088',
    [REQUEST_TYPE.COMMON]: 'http://192.168.20.9:8086',
    [REQUEST_TYPE.LANDONG]: 'https://shenyi-middlecontrol.test', //澜东无本地
    [REQUEST_TYPE.APP_KEY]: 'cli_a0be7ffd2ef85013', // TODO: 测试环境key
    [REQUEST_TYPE.CDB]: 'http://192.168.20.9', // TODO: 测试环境cdb
    [REQUEST_TYPE.COPS]: 'https://c-ops-backend-api.test',
  },
  [ENV.INTRANET]: {
    [REQUEST_TYPE.ADMIN]: 'https://middlecontrol-backend-api.test',
    [REQUEST_TYPE.PMS]: 'https://pms-backend-api.prod-v2',
    [REQUEST_TYPE.IMS]: 'https://ims-backend-api.prod-v2',
    [REQUEST_TYPE.WMS]: 'https://wms-backend-api.prod-v2',
    [REQUEST_TYPE.TWOI_WMS]: 'https://wms-backend-api.prod-v2',
    [REQUEST_TYPE.OMS]: 'https://oms-backend-api.prod-v2',
    [REQUEST_TYPE.TMS]: 'https://tms-backend-api.prod-v2',
    [REQUEST_TYPE.COMMON]: 'https://common-backend-api.prod-v2',
    [REQUEST_TYPE.LANDONG]: 'https://shenyi-middlecontrol.test',
    [REQUEST_TYPE.APP_KEY]: 'cli_a0be7ffd2ef85013', // TODO: 测试环境key
    [REQUEST_TYPE.CDB]: 'https://cdp-backend-api.test', // TODO: 测试环境key
    [REQUEST_TYPE.COPS]: 'https://c-ops-backend-api.test',
  },
  [ENV.TEST]: {
    [REQUEST_TYPE.ADMIN]: 'https://middlecontrol-backend-api.test',
    [REQUEST_TYPE.PMS]: 'https://pms-backend-api.test',
    [REQUEST_TYPE.IMS]: 'https://ims-backend-api.test',
    [REQUEST_TYPE.WMS]: 'https://wms-backend-api.test',
    [REQUEST_TYPE.TWOI_WMS]: 'https://wms-backend-api.test',
    [REQUEST_TYPE.OMS]: 'https://oms-backend-api.test',
    [REQUEST_TYPE.TMS]: 'https://tms-backend-api.test',
    [REQUEST_TYPE.COMMON]: 'https://common-backend-api.test',
    [REQUEST_TYPE.LANDONG]: 'https://shenyi-middlecontrol.test',
    [REQUEST_TYPE.APP_KEY]: 'cli_a0be7ffd2ef85013', // TODO: 测试环境key
    [REQUEST_TYPE.CDB]: 'https://cdp-backend-api.test', // TODO: 测试环境key
    [REQUEST_TYPE.COPS]: 'https://c-ops-backend-api.test',
    [REQUEST_TYPE.KOL]: 'https://kol-backend-api.test',
  },
  [ENV.PRE]: {
    [REQUEST_TYPE.ADMIN]: 'https://middlecontrol-backend-api.pre-prod',
    [REQUEST_TYPE.PMS]: 'https://pms-backend-api.pre-prod',
    [REQUEST_TYPE.IMS]: 'https://ims-backend-api.pre-prod',
    [REQUEST_TYPE.WMS]: 'https://wms-backend-api.pre-prod',
    [REQUEST_TYPE.OMS]: 'https://oms-backend-api.pre-prod',
    [REQUEST_TYPE.TMS]: 'https://tms-backend-api.pre-prod',
    [REQUEST_TYPE.COMMON]: 'https://common-backend-api.pre-prod',
    [REQUEST_TYPE.LANDONG]: 'https://shenyi-middlecontrol.pre',
    [REQUEST_TYPE.APP_KEY]: 'cli_a0a490a3bff8500c', // TODO: 高保真环境key
    [REQUEST_TYPE.CDB]: 'https://cdp-backend-api.pre-prod',
    [REQUEST_TYPE.COPS]: 'https://c-ops-backend-api.prod',
    [REQUEST_TYPE.KOL]: 'https://kol-backend-api.prod',
  },
  [ENV.PROD]: {
    [REQUEST_TYPE.ADMIN]: 'https://middlecontrol-backend-api.prod',
    [REQUEST_TYPE.PMS]: 'https://pms-backend-api.prod',
    [REQUEST_TYPE.IMS]: 'https://ims-backend-api.prod',
    [REQUEST_TYPE.WMS]: 'https://wms-backend-api.prod',
    [REQUEST_TYPE.TWOI_WMS]: 'https://wms-backend-api.prod',
    [REQUEST_TYPE.OMS]: 'https://oms-backend-api.prod',
    [REQUEST_TYPE.TMS]: 'https://tms-backend-api.prod',
    [REQUEST_TYPE.COMMON]: 'https://common-backend-api.prod',
    [REQUEST_TYPE.LANDONG]: 'https://shenyi-middlecontrol.prod',
    [REQUEST_TYPE.APP_KEY]: 'cli_a0a490a3bff8500c', // TODO: 正式环境key
    [REQUEST_TYPE.CDB]: 'https://cdp-backend-api.prod',
    [REQUEST_TYPE.COPS]: 'https://c-ops-backend-api.prod',
    [REQUEST_TYPE.KOL]: 'https://kol-backend-api.prod',
  },
  [ENV.LOCAL]: {
    [REQUEST_TYPE.ADMIN]: '/api',
    [REQUEST_TYPE.PMS]: '/pms',
    [REQUEST_TYPE.IMS]: '/ims',
    [REQUEST_TYPE.WMS]: '/wms',
    [REQUEST_TYPE.TWOI_WMS]: '/twoiWms',
    [REQUEST_TYPE.OMS]: '/oms',
    [REQUEST_TYPE.TMS]: '/ms',
    [REQUEST_TYPE.COMMON]: '/common',
    [REQUEST_TYPE.LANDONG]: '/landong',
    [REQUEST_TYPE.APP_KEY]: 'cli_a0be7ffd2ef85013', // TODO: 测试环境key
    [REQUEST_TYPE.CDB]: '/cdb', // TODO: 测试环境key
    [REQUEST_TYPE.COPS]: '/cops',
  },
}

export default config

export const isDev = import.meta.env.MODE === 'development'

export const getEnv = () => {
  if (isDev) {
    return ENV.TEST
  }

  if (import.meta.env.VITE_NODE_ENV == 'inner') {
    return ENV.INNER
  } else if (import.meta.env.VITE_NODE_ENV == 'test') {
    return ENV.TEST
  } else if (import.meta.env.VITE_NODE_ENV == 'intranet') {
    return ENV.INTRANET
  } else if (import.meta.env.VITE_NODE_ENV == 'pre') {
    return ENV.PRE
  } else if (import.meta.env.VITE_NODE_ENV == 'production') {
    return ENV.PROD
  }

  return ENV.PROD
}
export const envConfig = (() => config[getEnv()])()
