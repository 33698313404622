import { mountComponent, useModalState } from './mount-component'
import { Modal } from 'ant-design-vue'

let instance
let unmount

function initInstance() {
  const Wrapper = {
    setup(_, { expose }) {
      const { state, toggle }: any = useModalState(expose)

      const okButtonProps = reactive({
        loading: false,
      })
      const onOk = () => {
        const p = state.onOk && state.onOk()
        if (p.then) {
          okButtonProps.loading = true
          p.then(() => toggle(false)).finally(() => (okButtonProps.loading = false))
        } else {
          toggle(false)
        }
      }
      //  const okButtonProps = state.okButtonProps ? state.okButtonProps : { loading: loading.value }
      return () => (
        <Modal {...{ ...state, 'onUpdate:visible': toggle, onOk, okButtonProps,bodyStyle:{
          padding:'16px'
        } }}>
          {state.content}
        </Modal>
      )
    },
  }

  ;({ instance, unmount } = mountComponent(Wrapper))
}

function _Modal(options) {
  if (!instance) {
    initInstance()
  }
  instance.open({
    okText: '确认',
    cancelText: '取消',
    ...options,
    afterClose() {
      unmount()
      instance = null
    },
  })
}

export default _Modal
