// token key
export const ADMIN_TOKEN_KEY = 'ADMIN_TOKEN__'

// user id key
export const USER_ID_KEY = 'USER__ID__'
export const USER_NAME_KEY = 'USER__NAME__'

export const LOCALE_KEY = 'LOCALE__'
export const LOCALE_TEXT = 'LOCALE__TEXT'

// user info key
export const USER_INFO_KEY = 'USER__INFO__'

// role info key
export const ROLES_KEY = 'ROLES__KEY__'

// project config key
export const ADMIN_PROJ_CFG_KEY = 'ADMIN_PROJ_CFG_KEY'
export const SUPPULIER_PROJ_CFG_KEY = 'SUPPULIER_PROJ_CFG_KEY'

// lock info
export const LOCK_INFO_KEY = 'LOCK__INFO__KEY__'

export const MULTIPLE_TABS_KEY = 'MULTIPLE_TABS__KEY__'

export const APP_DARK_MODE_KEY_ = '__APP__DARK__MODE__'

export const HEALTH_URL = 'HEALTH_URL'

// base global local key
export const APP_LOCAL_CACHE_KEY = 'COMMON__LOCAL__KEY__'

// base global session key
export const APP_SESSION_CACHE_KEY = 'COMMON__SESSION__KEY__'

export enum CacheTypeEnum {
  SESSION,
  LOCAL,
}
