import { ref, watch } from 'vue'

import { isDef } from 'framework/utils/is'
import { useMessage } from 'framework/hooks/web/useMessage'
interface Options {
  target?: HTMLElement
}
export function useCopyToClipboard(initial?: string) {
  const clipboardRef = ref(initial || '')
  const isSuccessRef = ref(false)
  const copiedRef = ref(false)

  watch(
    clipboardRef,
    (str?: string) => {
      if (isDef(str)) {
        copiedRef.value = true
        isSuccessRef.value = copyTextToClipboard(str)
      }
    },
    { immediate: !!initial, flush: 'sync' },
  )

  return { clipboardRef, isSuccessRef, copiedRef }
}

export function copyTextToClipboard(value: string, { target = document.body }: Options = {}) {
  const input = (document as any).createElement('input') // 直接构建input
  input.value = value // 设置内容
  ;(document as any).body.appendChild(input) // 添加临时实例
  input.select() // 选择实例内容
  let isSuccess = false
  try {
    isSuccess = (document as any).execCommand('copy')
  } catch (e: any) {
    throw new Error(e)
  }

  (document as any).body.removeChild(input) // 删除临时实例
  return isSuccess
}

export function useCopy() {
  return (value) => {
    const { isSuccessRef } = useCopyToClipboard(value)
    const unSuccessRef = unref(isSuccessRef)
    const message = useMessage()
    message.createMessage[unSuccessRef ? 'success' : 'error'](
      unSuccessRef ? '复制成功' : '复制失败',
    )
  }
}
