import { LAYOUT } from '@admin/router/constant'

// 仓库管理
const storehouseRouter = {
  path: '/storehouseManagement',
  name: '',
  component: LAYOUT,
  children: [
    {
      path: '/storehouseManagement/physicalStorehouse',
      name: 'physicalStorehouse',
      component: () => import('@admin/views/wms/storehouseManagement/physicalStorehouse/index.vue'),
      meta: { title: '实体仓' },
    },
    {
      path: '/storehouseManagement/logicStorehouse',
      name: 'logicStorehouse',
      component: () => import('@admin/views/wms/storehouseManagement/logicStorehouse/index.vue'),
      meta: { title: '逻辑仓' },
    },
    {
      path: '/storehouseManagement/reservoirRegionManagement',
      name: 'reservoirRegionManagement',
      component: () =>
        import('@admin/views/wms/storehouseManagement/reservoirRegionManagement/index.vue'),
      meta: { title: '库区管理' },
    },
    {
      path: '/storehouseManagement/shelfManagement',
      name: 'shelfManagement',
      component: () => import('@admin/views/wms/storehouseManagement/shelfManagement/index.vue'),
      meta: { title: '货架管理' },
    },
    {
      path: '/storehouseManagement/basketManagement',
      name: 'basketManagement',
      component: () =>
        import('@admin/views/wms/storehouseManagement/basketManagement/basketManagement.vue'),
      meta: { title: '篮子管理' },
    },
    {
      path: '/storehouseManagement/distributionTable',
      name: 'distributionTable',
      component: () => import('@admin/views/wms/storehouseManagement/distributionTable/index.vue'),
      meta: { title: '分播台' },
    },
    {
      path: '/storehouseManagement/working-storage',
      name: 'workingStorage',
      component: () => import('@admin/views/wms/storehouseManagement/workingStorage/index.vue'),
      meta: { title: '暂存区' },
    },
  ],
}

export default [storehouseRouter]
