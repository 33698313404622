import type { GlobConfig } from 'framework/types/config'

import { warn } from 'framework/utils/log'
import { getAppEnvConfig } from '@admin/utils/env'

export const useGlobSetting = (): Readonly<GlobConfig> => {
  const {
    VITE_GLOB_APP_TITLE,
    VITE_GLOB_API_URL,
    VITE_GLOB_PMS_API_URL,
    VITE_GLOB_IMS_API_URL,
    VITE_GLOB_WMS_API_URL,
    VITE_GLOB_LANDONG_API_URL,
    VITE_GLOB_APP_SHORT_NAME,
    VITE_GLOB_API_URL_PREFIX,
    VITE_GLOB_UPLOAD_URL,
    VITE_GLOB_OMS_API_URL,
    VITE_GLOB_COPS_API_URL,
    VITE_GLOB_COLLECTOR,
    VITE_GLOB_SENTRY_KEY,
    VITE_GLOB_SENTRY_DSN,
    VITE_GLOB_TXCLOUD_ID,
  } = getAppEnvConfig()
  if (!/[a-zA-Z_]*/.test(VITE_GLOB_APP_SHORT_NAME)) {
    warn(
      `VITE_GLOB_APP_SHORT_NAME Variables can only be characters/underscores, please modify in the environment variables and re-running.`,
    )
  }

  // Take global configuration
  const glob: Readonly<GlobConfig> = {
    title: VITE_GLOB_APP_TITLE,
    apiUrl: VITE_GLOB_API_URL,
    pmsApiUrl: VITE_GLOB_PMS_API_URL,
    imsApiUrl: VITE_GLOB_IMS_API_URL,
    wmsApiUrl: VITE_GLOB_WMS_API_URL,
    landongApiUrl: VITE_GLOB_LANDONG_API_URL,
    shortName: VITE_GLOB_APP_SHORT_NAME,
    urlPrefix: VITE_GLOB_API_URL_PREFIX,
    uploadUrl: VITE_GLOB_UPLOAD_URL,
    omsApiUrl: VITE_GLOB_OMS_API_URL,
    copsApiUrl: VITE_GLOB_COPS_API_URL,
    skywalkCollector: VITE_GLOB_COLLECTOR,
    txCloudId: VITE_GLOB_TXCLOUD_ID,
    sentryKey: VITE_GLOB_SENTRY_KEY,
    sentryDsn: VITE_GLOB_SENTRY_DSN,
  }
  return glob as Readonly<GlobConfig>
}
