import { LAYOUT } from '@admin/router/constant'

// 应用系统路由
const systemRouter = {
  path: '/commonSystem',
  name: '',
  component: LAYOUT,
  children: [
    {
      path: '/announcement/announcement-list',
      name: 'announcementList',
      component: () => import('@admin/views/common/announcement/announcementList/index.vue'),
      meta: { helpUrl: '', title: '公告列表' },
    },
    {
      path: '/announcement/internal',
      name: 'internalAnnouncement',
      component: () => import('@admin/views/common/announcement/internal/index.vue'),
      meta: { helpUrl: '', title: '内部公告管理' },
    },
    {
      path: '/announcement/supplier',
      name: 'supplierAnnouncement',
      component: () => import('@admin/views/common/announcement/supplier/index.vue'),
      meta: { helpUrl: '', title: '供应商公告管理' },
    },
    {
      path: '/announcement/internal-edit',
      name: 'internalEdit',
      component: () => import('@admin/views/common/announcement/internal/edit.vue'),
      meta: { helpUrl: '', title: '编辑公告' },
    },
    {
      path: '/task-management/task-center',
      name: 'taskCenters',
      component: () => import('@admin/views/system/taskManagement/taskCenter/index.vue'),
      meta: { helpUrl: '', title: '任务配置' },
    },
    {
      path: '/message-managemant/message-center',
      name: 'messageCenter',
      component: () => import('@admin/views/system/messageManagement/messageCenter/index.vue'),
      meta: { helpUrl: '', title: '消息中心' },
    },
    {
      path: '/message-managemant/message-config',
      name: 'messageConfig',
      component: () => import('@admin/views/system/messageManagement/messageConfig/index.vue'),
      meta: { helpUrl: '', title: '消息配置' },
    },
    {
      path: '/commonSystem/flowChart',
      name: 'flowChart',
      component: () => import('@admin/views/system/workbench/flowChart.vue'),
      meta: { helpUrl: '', title: '业务流程地图' },
    },
    {
      path: '/permission/task',
      name: 'taskList',
      component: () => import('@admin/views/system/task/index.vue'),
      meta: { helpUrl: '', title: '我的任务' },
    },
    {
      path: '/permission/task-new',
      name: '',
      component: () => import('@admin/views/system/new-task/index.vue'),
      meta: { helpUrl: '', title: '我的任务（新）' },
    },
    {
      path: '/permission/departmentUser',
      name: 'departmentUser',
      component: () => import('@admin/views/system/departmentUser/index.vue'),
      meta: { helpUrl: '', title: '员工管理-新权限' },
    },
    {
      path: '/permission/departmentUserEdit',
      name: 'depUserEdit',
      component: () => import('@admin/views/system/departmentUser/depUserEdit.vue'),
      meta: { helpUrl: '', title: '员工管理详情-新权限' },
    },
    {
      path: '/permission/authority/role',
      name: 'authorityRole',
      component: () => import('@admin/views/system/permission/authority/role/index.vue'),
      meta: { helpUrl: '', title: '角色列表-新权限' },
    },
    {
      path: '/permission/authority/role/edit',
      name: 'roleEdit',
      component: () => import('@admin/views/system/permission/authority/role/edit.vue'),
      meta: { helpUrl: '', title: '角色列表详情-新权限' },
    },
    {
      path: '/permission/positionManage',
      name: 'positionManage',
      component: () => import('@admin/views/system/positionManage/index.vue'),
      meta: { helpUrl: '', title: '岗位管理-新权限' },
    },
    {
      path: '/permission/menu-old',
      name: 'menuOld',
      component: () => import('@admin/views/system/menu-old/index.vue'),
      meta: { helpUrl: '', title: '菜单管理' },
    },
    {
      path: '/userDetail',
      name: 'userDetail',
      component: () => import('@admin/views/system/user/userDetail/index.vue'),
      meta: {
        title: '用户信息',
      },
    },
    {
      path: '/resource/key',
      name: 'resourceKey',
      component: () => import('@admin/views/system/resource-key/index.vue'),
      meta: { helpUrl: '', title: '权限资源管理' },
    },
    {
      path: '/resource/key/add',
      name: 'resourceKeyAdd',
      component: () => import('@admin/views/system/resource-key/components/AEPage.vue'),
      meta: { helpUrl: '', title: '新建权限资源' },
    },
    {
      path: '/resource/key/edit/:id',
      name: 'resourceKeyEdit',
      component: () => import('@admin/views/system/resource-key/components/AEPage.vue'),
      meta: { helpUrl: '', title: '编辑权限资源' },
    },
    {
      path: '/permission/external-organization',
      name: 'externalOrganization',
      component: () => import('@admin/views/system/externalOrganization/index.vue'),
      meta: { helpUrl: '', title: '外部组织' },
    },
  ],
}

export default systemRouter
