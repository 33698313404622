import { LAYOUT } from '@admin/router/constant'

// gmp 人群
const GmpCrowdRouter = {
  path: '/gmp',
  name: '',
  component: LAYOUT,
  children: [
    {
      path: '/gmp/crowd/pack-list',
      name: 'GmpCrowdPackList',
      component: () => import('@admin/views/gmp/crowd/index/index.vue'),
      meta: {
        title: '人群圈选列表',
      },
    },
    {
      path: '/gmp/crowd/pack-detail',
      name: 'GmpCrowdPackDetail',
      component: () => import('@admin/views/gmp/crowd/index/detail.vue'),
      meta: {
        title: '人群包详情',
      },
    },
    {
      path: '/gmp/crowd/tag-list',
      name: 'GmpCrowdTagList',
      component: () => import('@admin/views/gmp/crowd/tag/index.vue'),
      meta: {
        title: '人群标签',
      },
    },
    {
      path: '/gmp/crowd/tag-detail',
      name: 'GmpCrowdTagDetail',
      component: () => import('@admin/views/gmp/crowd/tag/detail.vue'),
      meta: {
        title: '人群标签详情',
      },
    },
  ],
}

export default GmpCrowdRouter
